// read_more_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "button"]
  static values = {
    maxHeight: { type: Number, default: 100 },
    expandedLabel: { type: String, default: "Less" },
    collapsedLabel: { type: String, default: "...more" }
  }

  connect() {
    // Store the original height of the content
    this.originalHeight = this.contentTarget.scrollHeight

    // Check if the content exceeds the max height
    if (this.originalHeight > this.maxHeightValue) {
      this.collapse()
      this.buttonTarget.classList.remove("d-none")
    } else {
      // Hide the button if content is short
      this.buttonTarget.classList.add("d-none")
    }
  }

  toggle() {
    if (this.expanded) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  expand() {
    this.contentTarget.style.maxHeight = this.originalHeight + "px"
    this.contentTarget.style.overflow = "visible"
    this.buttonTarget.textContent = this.expandedLabelValue
    this.expanded = true
  }

  collapse() {
    this.contentTarget.style.maxHeight = this.maxHeightValue + "px"
    this.contentTarget.style.overflow = "hidden"
    this.buttonTarget.textContent = this.collapsedLabelValue
    this.expanded = false
  }
}